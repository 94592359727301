@font-face {
  font-family: 'Inter';
  src: url(../assets/fonts/Inter-Thin.ttf);
  font-weight: 100;
}

@font-face {
  font-family: 'Inter';
  src: url(../assets/fonts/Inter-ExtraLight.ttf);
  font-weight: 200;
}

@font-face {
  font-family: 'Inter';
  src: url(../assets/fonts/Inter-Light.ttf);
  font-weight: 300;
}

@font-face {
  font-family: 'Inter';
  src: url(../assets/fonts/Inter-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src: url(../assets/fonts/Inter-Medium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: url(../assets/fonts/Inter-SemiBold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: 'Inter';
  src: url(../assets/fonts/Inter-Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: 'Inter';
  src: url(../assets/fonts/Inter-ExtraBold.ttf);
  font-weight: 800;
}

@font-face {
  font-family: 'Inter';
  src: url(../assets/fonts/Inter-Black.ttf);
  font-weight: 900;
}

* {
  cursor: default !important;
}

html,
body {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
}

body {
  overflow-x: hidden;
}

/* apex-chart */
.apexcharts-tooltip {
  top: -24px !important;
  border: unset !important;
  overflow: hidden !important;
  background-color: transparent !important;
  box-shadow: unset !important;
}

.apexcharts-tooltip_arrow-box {
  padding: 8px 10px;
  border-radius: 3px;
  color: white;
}

.apexcharts-tooltip_sub-arrow-box {
  width: 2px;
  height: 500px;
  position: absolute;
  top: 38px;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
}

.apexcharts-xaxistooltip {
  opacity: 0 !important;
  background-color: transparent;
}

.apexcharts-xaxistooltip:after {
  color: red !important;
}

.apexcharts-series path {
  filter: unset !important;
}

.apexcharts-bar-area {
  filter: unset !important;
}

.apexcharts-marker {
  display: none;
}

.chakra-link {
  text-decoration: none;
}

.menu-magic-overlay {
  width: 350px;
  height: 270px;
  background-color: transparent;
  opacity: 0.2;
  position: absolute;
  top: -221px;
  left: -302px;
}

div[data-rbd-drag-handle-context-id] {
  cursor: auto;
}

/* Menu item */
.chakra-ui-dark .menu-item-title {
  color: rgb(255, 255, 255, 0.7);
}

.chakra-ui-light .menu-item-title,
.chakra-ui-light .menu-item-title svg {
  color: rgb(0, 0, 0, 0.7);
}

.chakra-ui-dark .menu-item-title svg {
  color: #fffffff2;
}

.menu-item-title:hover {
  color: white;
}

.menu-item-selected {
  color: #ffffff;
}

/* pulldown button */
.pulldown-button span:nth-child(1) {
  margin-right: 0;
}

.pulldown-button span:nth-child(2) {
  display: none;
}

.apexcharts-canvas {
  width: 100% !important;
}

body::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  /* border-radius: 10px; */
}

body::-webkit-scrollbar-track-piece {
  background: transparent;
}

.chakra-ui-dark::-webkit-scrollbar-thumb {
  background: #424242;
  border-radius: 10px;
}

.chakra-ui-light::-webkit-scrollbar-thumb {
  background: #a2a0a0;
  border-radius: 10px;
}

.chakra-ui-dark::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.chakra-ui-light::-webkit-scrollbar-thumb:hover {
  background: #777777;
}

.apexcharts-xaxis-labe--s {
  width: 5px;
  height: 3px;
  background-color: gray;
  color: red !important;
}
